<template>
  <div class="app-box">
    <x-search placeholder="请输入联系人姓名" @click.native="searchEvent"></x-search>
    <div class="list-index-box">
      <x-list-index tip="请前往客户中心维护员工信息" @itemClick="itemClick"></x-list-index>
    </div>
  </div>
</template>

<script>
import listIndex from "@/components/listIndex";
import search from "@/components/customerCenter/search";

export default {
  data () {
    return {};
  },
  created () { },
  methods: {
    searchEvent () {
      this.$router.push({
        path: `/searchPick/pick/${this.$route.params.customerId}`
      });
    },
    itemClick (item) {
      this.$store.commit("setSearchValue", item);
      this.$router.back();
    }
  },
  components: {
    [listIndex.name]: listIndex,
    [search.name]: search
  }
};
</script>

<style scoped>
.list-index-box {
  position: absolute;
  top: 0.52rem;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #F6F8FB;
}
</style>
